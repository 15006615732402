// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@import url('https://fonts.googleapis.com/css2?family=Open+Sans');
// @import 'firebaseui/dist/firebaseui.css';
@import '@smart-leiloes/firebaseui-web/firebaseui.css';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$bip-public-primary: mat.define-palette(mat.$indigo-palette);
$bip-public-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
// The warn palette is optional (defaults to red).
$bip-public-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$bip-public-theme: mat.define-light-theme(
  (
    color: (
      primary: $bip-public-primary,
      accent: $bip-public-accent,
      warn: $bip-public-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($bip-public-theme);

@mixin snack-theme($theme) {
  // Extract whichever individual palettes you need from the theme.
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  //Added with panelClass property
  .mat-mdc-snack-bar-container {
    --mdc-snackbar-container-color: #005ba8 !important;
    --mat-snack-bar-button-color: #fff;
  }
}

@include snack-theme($bip-public-theme);

/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

.clickable {
  &:hover {
    cursor: pointer;
  }
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

// Fix for Angular Material conflict on outline appearance
.mdc-notched-outline__notch {
  border-right: none;
}

.mat-mdc-form-field-text-prefix {
  width: 80px;
}

.text-orange-light {
  color: #f8a23d
}

.map-tooltip {
  white-space: pre-line !important;
}

.simulador-tooltip {
  .mdc-tooltip__surface {
    background-color: rgb(241, 240, 193) !important;
    color: rgb(31, 14, 187) !important;
    border: 2px solid rgb(3, 3, 3) !important;
    margin: 4% 1% 1% 1% !important;
    padding: 2%;
    border-radius: 6px;
    letter-spacing: 1px;
    font-size: 14px;
    line-height: 1.7;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
  }
}

.mat-step-header {
  // display: flex !important; // Não funcionou
  // justify-content: flex-start !important;
  // align-items: center !important;
  font-size: 1.3em;
  padding: 12px !important;
  font-weight: bold;
  color: #1f2a47 !important;
}

.mat-step-icon {
  background-color: #e09400 !important;
  margin-right: 32px !important;
}

// .mat-mdc-tooltip .mdc-tooltip__surface {
//   background-color: white !important;
//   color: black !important;
//   border: 2px solid black !important;
// }

@media only screen and (max-width: 450px) {
  .mat-vertical-content-container {
    margin-left: 6px !important;
  }
}
